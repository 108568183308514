<template>
	<div class="d-flex align-items-center min-vh-100">
		<CContainer fluid>
			<CRow class="justify-content-center">
				<CCol md="12" class="text-center">
					<h1><b>Registrasi Akun</b></h1>
					<h3>Registrasi Perusahaan Benih Tanaman</h3>
					<div class="card text-left">
						<div class="card-header bg-success text-left">
							<b style="color: white;">Informasi</b>
						</div>
						<div class="card-body" style="background-color: #f5fffb;">
							<ol style="padding-left: 20px;">
								<li>Pastikan NIB dan email sudah terdaftar pada OSS</li>
								<li>Password akun akan dikirimkan ke alamat email yang digunakan sebagai akun</li>
								<li>Jika NIB dan email sesuai, data perusahaan akan otomatis terisi sesuai data dari OSS</li>
							</ol>
						</div>
					</div>
				</CCol>
			</CRow>
			<CRow class="justify-content-center bg-white m-0">
				<CCol md="12">
					<div class="card text-left bg-transparent" style="border: 0px;">
						<div class="card-body">
							<div class="row">
								<div class="col-md-6">
									<h3 class="text-left pt-2">Isi Form Registrasi</h3>
									<hr >
									
									<div class="form-group">
										<b for="">Tipe Penyelenggara <span class="text-danger">*</span></b>
										<v-select :options="list_tipe_pemohon" label="tp_nama" v-model="tipe_pemohon" @input="changeTipePemohon()"></v-select>
									</div>
									<div class="form-group remove_hide form-nib" v-if="tipe_pemohon.is_nib">
										<b for=""> Nomor Induk Berusaha ( NIB ) Perusahaan / Perorangan <span class="text-danger required_span remove_hide">*</span> </b>
										<input type="text" class="form-control" placeholder="Masukkan NIB" v-on:input="showNibButton" v-model="nib_perusahaan">
									</div>
									<div class="form-group">
										<template>
											<div v-if="tipe_pemohon.is_nib">
												<b for="">Email Perusahaan / Perorangan <span class="text-danger">*</span></b>
												<small> Gunakan email perusahaan untuk cek nib</small>
											</div>
											<div v-else>
												<b for="">Email Instansi Pemerintah <span class="text-danger">*</span></b>
											</div>
										</template>
										<input type="text" class="form-control" placeholder="Masukkan Email" v-model="email_perusahaan" v-on:input="showNibButton">
									</div>
									<div class="form-group" id="btn-nib" style="display: none;">
										<button class="btn bg-info text-white btn-block text-center" v-on:click="checknib">Check NIB</button>
									</div>
									<div class="form-group">
										<b for="">Nama Perusahaan / Perorangan <span class="text-danger">*</span></b>
										<input type="text" class="form-control" placeholder="Masukkan Nama Perusahaan" v-model="dataperusahan.p_nama" :disabled="(dataperusahan.p_nama != '' && is_data_get)">
									</div>
									<div class="form-group">
										<b for="">Nama Penanggung Jawab <span class="text-danger">*</span></b>
										<input type="text" class="form-control" placeholder="Masukkan Nama Penanggung Jawab" v-model="dataperusahan.p_penanggung_jawab" :disabled="dataperusahan.p_penanggung_jawab != '' && is_data_get">
									</div>
									<div class="form-group">
										<b for="">Jenis Usaha <span class="text-danger">*</span></b>
										<v-select :options="master_jenis_perusahaan" label="jp_nama" multiple v-model="jenis_usaha"></v-select>
									</div>
									<div class="form-group d-none">
										<b for="">Klasifikasi Baku Lapangan Usaha Indonesia (KBLI) <span class="text-danger">*</span></b>
										<v-select multiple :options="master_kbli_perusahaan" label="kp_nama" v-model="perusahaan_kbli"></v-select>
									</div>
									<div class="form-group">
										<b for="">Nomor Pokok Wajib Pajak (NPWP) <span class="text-danger">*</span></b>
										<input type="text" class="form-control" placeholder="Masukkan NPWP" v-model="dataperusahan.p_npwp" :disabled="dataperusahan.p_npwp != '' && is_data_get" >
									</div>
									<div class="row">
										<div class="col-md-4">
											<div class="form-group">
												<b for="">No. Telp <span class="text-danger">*</span></b>
												<input type="text" class="form-control" placeholder="Masukkan No. Telp"  v-model="dataperusahan.p_telp" :disabled="dataperusahan.p_telp != '' && is_data_get" >
											</div>
										</div>
										<div class="col-md-4">
											<div class="form-group">
												<b for="">No. Handphone <span class="text-danger">*</span></b>
												<input type="text" class="form-control" placeholder="Masukkan No. Handphone" v-model="dataperusahan.p_hp" :disabled="dataperusahan.p_hp != '' && is_data_get">
											</div>
										</div>
										<div class="col-md-4">
											<div class="form-group">
												<b for="">FAX <span class="text-danger">*</span></b>
												<input type="text" class="form-control" placeholder="Masukkan FAX" v-model="dataperusahan.p_fax" :disabled="dataperusahan.p_fax != '' && is_data_get">
											</div>
										</div>
									</div>
									<div class="row">
										<div class="col-md-4">
											<div class="form-group">
												<b for="">Provinsi<span class="text-danger">*</span></b>
												<!-- <input type="text" :value="provinsi_selected.pr_nama" class="form-control"> -->
												<v-select :options="master_provinsi" label="pr_nama" v-model="provinsi_selected" @input="kota_selected = [], kecamatan_selected = []" :disabled="provinsi_selected != [] && is_data_get"></v-select>
											</div>
										</div>
										<div class="col-md-4">
											<div class="form-group">
												<b for="">Kabupaten / Kota <span class="text-danger">*</span></b>
												<v-select :options="provinsi_selected.kota" label="k_nama" v-model="kota_selected" @input="kecamatan_selected = []" :disabled="kota_selected != [] && is_data_get"></v-select>
											</div>
										</div>
										<div class="col-md-4">
											<div class="form-group">
												<b for="">Kecamatan <span class="text-danger">*</span></b>
												
												<v-select :options="kota_selected.kecamatan" label="kc_nama" v-model="kecamatan_selected" :disabled="kecamatan_selected != [] && is_data_get"></v-select>

											</div>
										</div>
									</div>
									<div class="form-group">
										<b for="">Kode Pos <span class="text-danger">*</span></b>
										<input type="text" class="form-control" v-model="dataperusahan.p_kode_pos" :disabled="dataperusahan.p_kode_pos != '' && is_data_get" >
									</div>
									<div class="form-group">
										<b>Alamat ( Sesuai NPWP ) <span class="text-danger">*</span></b>
										<textarea class="form-control" rows="4"  v-model="dataperusahan.p_alamat" :disabled="dataperusahan.p_alamat != '' && is_data_get"></textarea>
									</div>
								</div>
								<div class="col-md-6">
									<h3 class="text-left pt-2">Isi Form Akun</h3>
									<hr >
									<div class="row">
										<div class="col-12">
											<!-- <div class="form-group ">
												<b>NIP <span class="text-danger">*</span></b>
												<input type="text" class="form-control" id="nip">
											</div> -->
											<div class="form-group">
												<b>Nama<span class="text-danger">*</span></b>
												<input type="text" class="form-control" id="nama">
											</div>
											<div class="form-group">
												<b>Email Pendaftar <span class="text-danger">*</span></b>
												<small> Digunakan Untuk Login</small>
												<input type="email" class="form-control" id="email">
											</div>
											<div class="form-group">
												<b>Password <span class="text-danger">*</span></b>
												<input type="password" class="form-control" id="password">
											</div>
											<div class="form-group">
												<b>Konfirmasi Password <span class="text-danger">*</span></b>
												<input type="password" class="form-control" id="confirmpassword" >
											</div>
											<!-- <div class="form-group">
												<b>NIB<span class="text-danger">*</span></b>
												<input type="text" class="form-control" id="nibmember">
											</div>
											<div class="form-group">
												<b>No. Telp <span class="text-danger">*</span></b>
												<input type="text" class="form-control" id="phone">
											</div>
											<div class="form-group">
												<b>NPWP <span class="text-danger">*</span></b>
												<input type="text" class="form-control" id="npwpmember">
											</div> -->
										</div>
										<div class="col-12" v-if="(tipe_pemohon.is_nib && is_found) || !tipe_pemohon.is_nib">
											<button class="btn btn-success text-center btn-block" v-on:click="registerProcess">Daftar</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</CCol>
			</CRow>
		</CContainer>
	</div>
</template>
<script>
	// $(document).ready(function() {
	// 		$(".select2").select2();
	// 	});
	import Multiselect from 'vue-multiselect'
	const null_array = [];
	export default {
		name: "Register",
		components: {
		    Multiselect
		},
		data(){
			return {
				nib_perusahaan: '',
				email_perusahaan: '',
				options: null_array,
				value: '',
				list_tipe_pemohon: null_array,
				master_jenis_perusahaan: null_array,
				master_kbli_perusahaan: null_array,
				master_provinsi: null_array,
				provinsi_selected: null_array,
				kota_selected: null_array,
				kecamatan_selected: null_array,
				kelurahan_selected: null_array,
				tipe_pemohon: null_array,
				jenis_usaha: '',
				is_search: false,
				dataperusahan: [],
				is_data_get: true,
				perusahaan_kbli: [],
				is_found: false
			}
		},
		methods: {
			changeTipePemohon(){
				// $('.remove_hide').removeClass('d-none');
				this.is_data_get = true;
				// console.log(this.list_tipe_pemohon);
				if(this.tipe_pemohon.is_nib == true){
					
					// $('.form-nib').addClass('d-none');
				}
				else{
					this.is_data_get = false;
					// $('.required_span').addClass('d-none');
				}
				

			},
			showNibButton: function(){
				var nibperusahaan = this.nib_perusahaan;
				
				var emailperusahaan = this.email_perusahaan;
				if (nibperusahaan && emailperusahaan ) {
					$('#btn-nib').show();
				}
				else{
					$('#btn-nib').hide();
				}
			},
			checknib: function(){
				Swal.fire({
					title: '<i class="fa fa-refresh fa-spin"></i>',
					text: "Mohon tunggu sebentar...",
					showConfirmButton: false,
				});
				this.is_search = true;
				this.dataperusahan = [];
				axios.get(this.apiLink+"api/getperusahaan", {
				  params: {
				    nib: this.nib_perusahaan,
				  },
				}).then((response) => {
                    var res_data = response.data;
                    if (res_data.meta.code != "200") {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: res_data.data.message,
                        });
                    } else {
						this.is_data_get = true;
                    	var dataperusahan = res_data.data[0].data;
                    	this.dataperusahan = dataperusahan;
						this.kelurahan_selected = dataperusahan.kelurahan;
						this.kecamatan_selected = dataperusahan.kelurahan.kecamatan;
						this.kota_selected = dataperusahan.kelurahan.kecamatan.kota;
						this.provinsi_selected = dataperusahan.kelurahan.kecamatan.kota.provinsi;
						this.is_found = true;
						// console.log(dataperusahan.kelurahan.kecamatan);
						Swal.close();
                    }
				}).catch((error) => {
                    // console.log(error.response.data.data.message);
                    if (error) {
                        if (error?.response?.data?.data?.message == "Service inqueryNIB hanya untuk jenis NIB Perseorangan dan Non Perseorangan") {

                            Swal.fire({
                                icon: "error",
                                title: "Gagal",
                                text: 'NIB IMUK tidak dapat diajukan dalam permohonan Pemasukan dan Pengeluaran Benih Hortikultura Hanya NIB Perorangan (untuk koleksi) dan NIB Perusahaan Berbadan Usaha',
                            });
                        }
                        else{
							if(error?.response?.data?.data?.message){
								Swal.fire({
									icon: "error",
									title: "Gagal",
									text: error.response.data.data.message,
								});
							}
							else{
								Swal.fire({
									icon: "error",
									title: "Gagal",
									text: "Pastikan Data Perusahaan Pada OSS Lengkap",
								});
							}
                            
                        }
						this.is_found = false;
                    }
                });;
			},
			redirectLogin: function () {
				this.$router.push("login");
			},
			registerProcess: function (message) {
				let self = this;
				Swal.fire({
					title: '<i class="fa fa-refresh fa-spin"></i>',
					text: "Mohon tunggu sebentar...",
					showConfirmButton: false,
				});
				if (!this.is_search && this.tipe_pemohon.is_nib) {
					Swal.fire({
						icon: 'error',
						title: 'Gagal',
						text: 'Check NIB Perusahaan / Perorangan terlebih dahulu'
					});
					return false;
				}
				var jenis_usaha_clear = [];
				$.each(this.jenis_usaha, function(index, val) {
					jenis_usaha_clear.push(val.id);
				});
				// console.log(jenis_usaha_clear);
				axios.post(this.apiLink+'api/register', {
					// Users
					name: $('#nama').val(),
					nip: $('#nip').val(),
					email: $('#email').val(),
					password: $('#password').val(),
					password_confirmation: $('#confirmpassword').val(),
					// Perusahaan
					nib: self.nib_perusahaan,
					tipe_pemohon_id: this.tipe_pemohon.id,
					p_nama: self.dataperusahan.p_nama,
					p_npwp: self.dataperusahan.p_npwp,
					p_penanggung_jawab: self.dataperusahan.p_penanggung_jawab,
					p_email: self.email_perusahaan,
					p_telp: self.dataperusahan.p_telp,
					p_alamat: self.dataperusahan.p_alamat,
					jenis_usaha_json: JSON.stringify(jenis_usaha_clear) ,
					perusahaan_kbli_json: JSON.stringify(self.perusahaan_kbli),
					p_hp: self.dataperusahan.p_hp,
					p_fax: self.dataperusahan.p_fax,
					provinsi_id: this.provinsi_selected.id,
					kota_id: this.kota_selected.id,
					kecamatan_id: this.kecamatan_selected.id,
					kelurahan_id: this.kelurahan_selected.id,
					p_kode_pos: self.dataperusahan.p_kode_pos,
				}).then((response) => {
					let timerInterval;
					Swal.fire({
						icon: "success",
						title: "Berhasil",
						text: "Data Registrasi Anda Berhasil Disimpan, Anda akan diarahkan ke halaman login segera",
						timer: 2000,
						timerProgressBar: true,
						showCancelButton: false,
						showConfirmButton: false,
						didOpen: () => {
							timerInterval = setInterval(() => {
								const content = Swal.getContent();
								if (content) {
									const b = content.querySelector("b");
									if (b) {
										b.textContent = Swal.getTimerLeft();
									}
								}
							}, 100);
						},
						willClose: () => {
							clearInterval(timerInterval);
						},
					}).then((result) => {
						/* Read more about handling dismissals below */
						if (result.dismiss === Swal.DismissReason.timer) {
						}
					});
					setTimeout(this.redirectLogin, 1500);
				}).catch((error) => {
					if (error) {
						Swal.fire({
							icon: 'error',
							title: 'Oops...',
							text: 'Terjadi Kesalahan, Coba Lagi Nanti'
						})
					}
				});
			},
		},
		created() {
			// Get Master Tipe Pemohon
			axios.get(this.apiLink + "api/master/tipe_pemohon", {
			})
			.then((response) => {
				var res_tipe_pemohon = response.data;
				var res_tipe_pemohon_data = res_tipe_pemohon.data;
				if (res_tipe_pemohon.meta.code != "200") {
					Swal.fire({
						icon: 'error',
						title: 'Oops...',
						text: res_tipe_pemohon.data.message
					});
				}
				else{
					this.list_tipe_pemohon = res_tipe_pemohon_data.master;
				}
			});
			// Get Master Jenis Perusahaan
			axios.get(this.apiLink + "api/master/jenis_perusahaan", {
			})
			.then((response) => {
				var res_master_jenis_perusahaan = response.data;
				var res_master_jenis_perusahaan_data = res_master_jenis_perusahaan.data;
				if (res_master_jenis_perusahaan.meta.code != "200") {
					Swal.fire({
						icon: 'error',
						title: 'Oops...',
						text: res_master_jenis_perusahaan.data.message
					});
				}
				else{
					this.master_jenis_perusahaan = res_master_jenis_perusahaan_data.master;
				}
			});
			// Get master kbli
			axios.get(this.apiLink + "api/master/kbli_perusahaan", {
			})
			.then((response) => {
				var res_master_kbli_perusahaan = response.data;
				var res_master_kbli_perusahaan_data = res_master_kbli_perusahaan.data;
				if (res_master_kbli_perusahaan.meta.code != "200") {
					Swal.fire({
						icon: 'error',
						title: 'Oops...',
						text: res_master_kbli_perusahaan.data.message
					});
				}
				else{
					this.master_kbli_perusahaan = res_master_kbli_perusahaan_data.master;
				}
			});
			// Get Master Daerah (Provinsi)
			axios.get(this.apiLink + "api/master/chain_daerah", {
				
			})
			.then((response) => {
				var res_master_daerah = response.data;
				var res_master_daerah_data = res_master_daerah.data;
				if (res_master_daerah.meta.code != "200") {
					Swal.fire({
						icon: 'error',
						title: 'Oops...',
						text: res_master_kbli_perusahaan.data.message
					});
				}
				else{
					this.master_provinsi = res_master_daerah_data.master;
					console.log(this.master_provinsi);
				}
			});
		},
	};
</script>